@import "~bootstrap/scss/bootstrap";

body{
    font-size: Helvetica, Arial, sans-serif;
	background: rgb(20,20,20);
    color: rgb(153,153,153);
    margin: 0 auto;
    max-width: 940px;   
    
}

header{
	margin-top: 20px;

	.navbar, .navbar-expand-lg .navbar-nav .nav-link{
		padding-left: 0;
	}

	li{
		margin-right: 1rem;
	}
}

footer{
	text-align: right;
    font-size: 0.9em;
    border-top: 1px solid rgb(153,153,153);
    padding-top: 1rem;
    margin-top: 2rem;
}